@import '../../variables.scss';

.ProfitChart {
  position: relative;
  z-index: 1;
  
  .title {
    font-weight: 600;
    font-size: 1.1em;
    color: $purple;
    padding: $md-space $md-space;
    margin-bottom: -60px;
    position: relative;
    z-index: 1;
  }
  .VictoryContainer svg {
    margin-bottom: -7px;
    border-radius: 0 0 $radius $radius;
  }
}