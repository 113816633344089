@import '../../../variables.scss';

.cardList-item {
  position: relative;
  background-color: #FFF;
  border: 1px solid $grey;
  padding: $md-space $lg-space;
  display: flex;
  flex-direction: column;
  margin-bottom: -1px;

  &:first-child {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  .cardList-item-actions-menu {
    position: absolute;
    top: $md-space;
    right: $lg-space;
    border: 0;
    box-shadow: none;
    padding: 0 0 $md-space 0 !important;
    border-radius: 0 !important;
    font-size: 1.3em;
    line-height: 1em;
    color: $grey;
    min-width: auto;
    text-align: unset;
    display: unset;
    margin: unset;

    &:hover {
      .anticon {
        transform: none;
      }
    }
  }

  .cardList-item-content {
    text-decoration: none;
    color: lighten($black, 60%);
    
    .cardList-item-title-box {
      .cardList-item-title {
        font-weight: bold;
        font-size: 1.2em;
        color: $purple;
      }
    }
  }

  .cardList-item-data-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: $md-space;

    .cardList-item-list {
      margin-right: auto;
      max-height: 80px;
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      gap: 0 $sm-space;
      
      &-item {
        display: flex;
        align-items: center;
        margin: 2px 0;

        &-icon {
          color: $purple;
          margin-right: $sm-space;
        }
        &-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          max-width: 170px;
        }
      }
    }

    .cardList-item-extraData {
      margin-left: auto;
      margin-top: auto;
      text-align: right;

      & + .cardList-item-list {
        flex-wrap: nowrap;
        max-height: auto;
      }
    }
  }
}