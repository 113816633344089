/* PROJECT VARIABLES */

$black: #1B1B51;
$purple: #6A52EE;
$grey: #D7E2EF;
$red: #F93D15;
$green: #2DD673;
$yellow: #FFC700;

$xl-space: 48px;
$lg-space: 32px;
$md-space: 16px;
$sm-space: 8px;

$mobile-width: 640px;
$tablet-width: 1024px;

$lg-radius: $xl-space;
$radius: $md-space;

$transition: .2s ease-in-out;

$font-family: 'Poppins';