@import '../../variables.scss';

.dateRangeNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $sm-space 0 0 0;

  .dateRangeNav-button {
    background: transparent;
    padding: 0 !important;
    border: 0;
    color: #FFF;
    height: auto;
    width: 92px;
    min-width: 0;
    margin: 0;
    justify-content: unset;
    font-size: .9em;

    &:hover {
      background: transparent !important;

      .anticon {
        transform: none;
      }
    }

    &.next {
      justify-content: flex-end;
    }
    &:disabled {
      opacity: .5;
      background: none;
    }
  }

  .dateRangeNav-current {
    font-size: 1.1em;
    white-space: nowrap;
  }
}