@import '../../variables.scss';

.total-profit {
  .total-profit-label {
    font-size: .9em;
    line-height: 1em;
  }
  .total-profit-amount {
    font-weight: bold;
    font-size: 1.2em;
    color: $purple;
  }
}

.Locations {
  .cardList {
    margin-top: $lg-space;
  }
}