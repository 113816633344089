/* src/pages/styles.scss */

.CustomSearch {
  .custom-search-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;

    .results-section {
      margin-top: 40px;
    }

    .no-data {
      margin-top: 20px;
    }

    .form-submit-button {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .ant-btn {
        display: flex;
        align-items: center;
        padding: 0 12px; /* Reduz o padding para tornar o botão mais compacto */
        font-size: 14px; /* Reduz o tamanho da fonte */
        height: 36px; /* Define uma altura fixa menor */
      }

      .search-icon {
        margin-left: 6px; /* Ajusta o espaçamento entre o texto e o ícone */
        display: flex;
        align-items: center;
      }
    }

    /* Custom styles para DatePicker e TimePicker */
    .ant-picker, .ant-picker-time {
      width: 100%;
      font-size: 14px; /* Reduz o tamanho da fonte para mobile */
    }

    /* Ajustes para TimePicker com size="small" */
    .ant-picker-small {
      padding: 4px 8px;
      font-size: 12px;
    }

    @media (min-width: 576px) {
      /* Ajustes para telas maiores */
      .ant-picker, .ant-picker-time {
        font-size: 14px;
      }
    }

    /* Ajustes específicos para mobile */
    @media (max-width: 576px) {
      .ant-form-item-label > label {
        font-size: 14px;
      }

      .ant-picker, .ant-picker-time {
        font-size: 14px;
      }

      .form-submit-button .ant-btn {
        padding: 6px 12px;
        font-size: 14px;
        height: 36px;
      }
    }

    /* Ajustes adicionais para melhorar a usabilidade em mobile */
    @media (max-width: 768px) {
      .form-submit-button {
        flex-direction: column;

        .ant-btn {
          width: 100%;
          justify-content: center;
        }
      }

      .results-section {
        margin-top: 20px;
      }

      .no-data {
        margin-top: 10px;
      }
    }

    /* Estilização adicional para melhorar a aparência */
    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-label > label {
      font-weight: 500;
    }

    .results-section h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    .totals-section h3 {
      text-align: center;
      margin-bottom: 10px;
    }

    /* Estilo para a tabela de resultados */
    .custom-table {
      width: 100%;
      font-size: 12px; /* Reduz o tamanho da fonte na tabela */

      .ant-table-thead > tr > th {
        font-size: 12px; /* Reduz o tamanho da fonte dos headers */
        padding: 12px; /* Ajusta o padding para menor altura das linhas */
      }

      .ant-table-tbody > tr > td {
        font-size: 12px; /* Reduz o tamanho da fonte dos dados */
        white-space: nowrap; /* Evita quebra de linha */
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px; /* Ajusta o padding para menor altura das linhas */
      }

      /* Estilos para Profit column */
      .profit-positive {
        color: green;
        font-weight: 500; /* Opcional: Torna o texto mais destacável */
      }

      .profit-negative {
        color: red;
        font-weight: 500; /* Opcional: Torna o texto mais destacável */
      }
    }

    /* Estilo para a tabela de totais */
    .totals-table {
      width: 100%;
      font-size: 14px; /* Tamanho da fonte maior para destaque */
      text-align: center;

      .ant-table-thead > tr > th {
        font-size: 14px;
        padding: 10px;
        background-color: #fafafa;
      }

      .ant-table-tbody > tr > td {
        font-size: 14px;
        padding: 10px;
      }

      /* Estilos para Profit column */
      .profit-positive {
        color: green;
      }

      .profit-negative {
        color: red;
      }
    }
  }
}