.invoice-status-dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #ccc;

  &.green {
    background-color: #52c41a;
  }
  &.yellow {
    background-color: #faad14;
  }
  &.red {
    background-color: #f5222d;
  }
  &.grey {
    background-color: #bfbfbf;
  }
}
