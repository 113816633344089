@import '../../variables.scss';

.Tasks {  
  .task-status-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $grey;
    top: 24px;
    left: 12px;
    border-radius: 50%;

    &.red { background-color: $red; }
    &.yellow { background-color: $yellow; }
    &.green { background-color: $green; }

    &.anticon {
      position: relative;
      top: -2px;
      left: 0;
      margin: 0 3px;
    }
  }
}