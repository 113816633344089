@import '../../variables.scss';

.dateRangeRadio {
  .ant-radio-group {
    width: 100%;
    display: flex;
    border-radius: $radius;
    border: 1px solid rgba($color: #FFF, $alpha: .3);
    background-color: rgba($color: #FFF, $alpha: .2);

    & .dateRangeRadio-item {
      border-radius: $radius;
      border: 0;
      background-color: transparent;
      height: auto;
      color: #FFF;
      flex: 1;
      text-align: center;
      padding: $sm-space $md-space;

      &::before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #FFF;
        color: $purple;
      }

      span {
        font-size: .8em;
      }
    } 
  }
}