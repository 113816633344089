.logout-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;

  button {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn-primary {
    background-color: #ff4d4f; /* Cor vermelha para o botão "Confirm Logout" */
    border-color: #ff4d4f;
  }

  .ant-btn-default {
    background-color: #f0f0f0; /* Cor cinza para o botão "Back" */
    border-color: #d9d9d9;
  }
}

.ant-drawer-title {
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
}