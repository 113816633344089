@import '../../../variables.scss';

.auth-page {
  background: #FFF url('../../../assets/images/login-arc.svg') no-repeat top center;
  background-size: cover;
  max-width: $mobile-width;
  width: 100vw;
  min-height: 100dvh;
  position: relative;
  padding: $xl-space;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth-logo {
    position: absolute;
    top: $md-space;
    left: $xl-space;

    img{
      width: 100px;
    }
  }

  h1 {
    line-height: 1em;
  }

  @media screen and (min-width: $mobile-width) {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      z-index: -1;
      pointer-events: none;
    }
  }
}