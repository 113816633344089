@import './variables.scss';

body {
  margin: 0;
  font-family: $font-family, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: $black;
  background-color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

*,
:root {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.mb-lg {
  margin-bottom: $lg-space;
}

.link-text {
  color: $purple;
  text-decoration: underline;
  transition: $transition;

  &:hover {
    color: $black;
  }
}

.section-title {
  font-size: 1.2em;
  color: $purple;
  font-weight: 600;
  margin: $lg-space 0 $md-space 0;
}

.ant-btn {
  padding: $md-space $lg-space !important;
  border-radius: $radius !important;
  height: auto;
  min-width: 200px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  .anticon {
    transition: $transition;
  }

  &:hover {
    .anticon {
      transform: translateX(4px);
    }
  }

  &:disabled {
    background-color: $grey;
  }
}

.ant-form-item,
.form-item {
  margin-bottom: $md-space;

  &-control {
    max-width: 100%;
  }

  &-label {
    font-family: $font-family;
    padding-bottom: 0 !important;
    margin-bottom: $sm-space/2;
  }

  .ant-input-affix-wrapper {
    border-radius: $radius !important;
    padding: 0 $lg-space 0 0;
  }

  .ant-select {
    height: auto !important;
    width: 100%;
  }

  .ant-input,
  .ant-picker,
  .ant-select-selector {
    font-family: $font-family;
    padding: $md-space $lg-space !important;
    border-radius: $radius !important;
    font-size: 1em;
    height: auto !important;
  }
}

.ant-drawer-content-wrapper {
  border-radius: 50px 50px 0 0;
  overflow: hidden;
  max-width: $mobile-width !important;


  @media screen and (min-width: $mobile-width) {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  &::after {
    content: '';
    position: absolute;
    top: $md-space;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 8px;
    background-color: $grey;
    border-radius: $radius;
  }

  .ant-drawer-header {
    border: 0;
    padding: calc($xl-space + $md-space) $lg-space $md-space $lg-space;

    .ant-drawer-title {
      font-size: 1.4em;
      font-weight: bold;
    }
  }

  .ant-drawer-body {
    padding: $md-space $lg-space;
  }

  // style.scss ou style.css


}