.NewTasks {
  .new-tasks-form {
    .detail-card {
      margin-top: 16px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background-color: #f5f5f5;
    }
  }
}