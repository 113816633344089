@import '../../variables.scss';

.float-button {
  position: fixed;
  bottom: 120px;
  right: $md-space;
  z-index: 999;
  font-size: 2em;
  color: #FFF;
  background: $green;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: $mobile-width) {
    right: calc(50% - ($mobile-width / 2) + $md-space);  
  }
}