@import '../../variables.scss';

.app-header {
  position: relative;
  color: #FFF;
  margin: calc($lg-space * -1);
  padding: $lg-space;
  z-index: 0;

  &::after {
    content: '';
    width: 100%;
    height: 1000%;
    background-color: $purple;
    position: absolute;
    bottom: -$xl-space;
    left: 0;
    z-index: -1;
    transform: skewY(162deg);
    box-shadow: 0px 30px 0 lighten($purple,30%);
  }

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .header-data {
      display: flex;
      align-items: center;
    }

    .title-without-subtitle {
      font-weight: bold;
      font-size: 1.6em;
    }

    .title-with-subtitle {
      font-weight: bold;
      line-height: 1.4em;

      .title {
        font-size: 1.4em;
      }
      .subtitle {
        font-size: 1em;
      }
    }

    .refresh-btn {
      margin-left: $md-space;
    }

    .back-btn {
      margin-left: -$md-space;
      margin-right: $md-space;
      color: inherit;
    }
  }

  .header-components {
    margin: $lg-space 0;
  }
}