@import '../../../variables.scss';

.ManageMenuItem {
  background: #FFF;
  display: flex;
  flex-direction: column;
  padding: $md-space;
  border-radius: $radius;
  text-decoration: none;
  color: $purple;
  align-items: flex-start;
  border: 1px solid $grey;

  &.style-boxed {
    width: calc(50% - ($md-space / 2));
  }

  &.style-center {
    border-radius: 0;
  }

  .menu {
    &-icon {
      font-size: 2em;
      margin-top: -10px;
    }
    &-title {
      font-size: 1em;
      margin-bottom: 4px;
    }
    &-subtitle {
      color: $grey;
      line-height: 1.1em;
      font-size: 0.7em;
    }
  }
}