@import '../../variables.scss';

.app-menu {
  background-color: $purple;
  border-radius: $radius $radius 0 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  max-width: $mobile-width;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  font-size: .8em;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $md-space;
    list-style: none;
    padding: 0 $md-space;
    margin: 0;

    li {
      position: relative;
      opacity: .4;
      transition: $transition;
      max-width: 72px;
      width: 72px;
      width: 100%;
      height: 72px;


      &::after {
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: transparent;
      }

      &.menu-active {
        opacity: 1;

        &::after {
          background-color: #FFF;
        }
      }

      a {
        display: flex;
        gap: 4px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFF;
        text-decoration: none;
        width: 100%;
        height: 100%;

        .anticon {
          font-size: 1.8em;
          color: #FFF;
        }
      }
    }
  }
}