@import '../../../variables.scss';

.app-container {
  background-color: #FFF;
  max-width: $mobile-width;
  width: 100vw;
  min-height: 100dvh;
  position: relative;
  padding: $xl-space $lg-space;

  & > div {
    padding-bottom: 100px;
  }

  @media screen and (min-width: $mobile-width) {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      z-index: -1;
      pointer-events: none;
    }
  }
}