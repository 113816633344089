@import '../../variables.scss';

.cashCards {
  display: flex;
  flex-wrap: wrap;
  gap: $md-space;
  margin: $md-space 0;

  .cashCard {
    width: calc(50% - $sm-space);
    border-radius: $radius;
    padding: $md-space 2px $md-space $md-space;
    border: 1px solid $grey;
    background-color: #FFF;
    display: flex;
    gap: $sm-space;
    align-items: center;

    .anticon {
      font-size: 1.5em;
    }

    &.red .anticon {
      color: $red;
    }

    &.green .anticon {
      color: $green;
    }

    &.purple .anticon {
      color: $purple;
    }

    &.yellow .anticon {
      color: $yellow;
    }

    &.growthBar {
      background-color: $green;
      padding: $md-space;
      color: #FFF;
      overflow: hidden;
      position: relative;
      z-index: 0;
      width: 100%;
      justify-content: center;
      align-items: center;

      &.negative {
        background: $red;

        .anticon {
          transform: scaleX(-1);
        }
      }

      .bg-icon {
        position: absolute;
        color: $black;
        opacity: .1;
        z-index: -1;
        font-size: 8em;
        top: -25%;
        left: -10%;
      }

      .growthPercentage {
        font-size: 2em;
        font-weight: 600;
        line-height: 0;
      }

      .growthDescription {
        max-width: 150px;
        font-size: .8em;
        line-height: 1.2em;
      }
    }

    .cashCard-number {
      font-weight: 600;
      font-size: 1em;
      color: $purple;
    }

    .cashCard-label {
      opacity: .4;
      font-size: .8em;
      line-height: 1.2em;
    }
  }
}