@import '../../variables.scss';

.Manage {
  .featured-menu-items {
    display: flex;
    gap: $md-space;
    flex-wrap: wrap;
    margin-bottom: $md-space;
  }

  .menu-list {
    margin-bottom: -200px;

    .ManageMenuItem{
      margin-bottom: -1px;

      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .bottom-menu {
    margin-top: 232px;
  }
}